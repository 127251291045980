import _ from 'lodash';
import { isApplicationStubbed } from './routeUtils';
/**
 *  This section is hidden under story number SCB-9684(https://wpb-jira.systems.uk.hsbc/browse/SCB-9684)
 * [Manage Beneficiary - PIB] Display Beneficiary % allocation and not Amount
 */
// export const IsDeathBenefitAmountCal = (
//   percentToGet,
//   total = 0,
//   currency = ''
// ) => {
//   return `${_.floor((_.toNumber(percentToGet) / 100) * total, 2)} ${currency}`;
// };

export const COMPANY_1_ENTITY_CODE = '1';

export const AllocationPercentConverter = allocation => {
  return _.trim(allocation, ' ') !== '' ? `${allocation}%` : ``;
};

export const getModifyUpdateJourneyUrl = (urlConfig, encodedProductId) => {
  const stubAPIData = 'stubAPIData';
  const { origin } = window.location;
  const { url, urlParam } = urlConfig;
  const baseUrl = url ?? '';
  const urlparam = urlParam ? `${urlParam}/` : '';
  if (isApplicationStubbed()) {
    return `${origin}/${urlparam}${stubAPIData}${baseUrl}${encodedProductId}`;
  }
  return `${baseUrl}${encodedProductId}`;
};

export const getInvolvePartyDetails = (
  involvedParties,
  singleBenef,
  beneficiaryType
) => {
  let benInvolObj;
  involvedParties.forEach(eachInvlParty => {
    if (
      eachInvlParty.involvedPartyRole.findIndex(
        eachRole => eachRole === 'Beneficiary'
      ) !== -1 &&
      eachInvlParty?.involvedPartyIdentificationNumber ===
        singleBenef?.involvedPartyIdentificationNumber
    ) {
      benInvolObj = {
        involvedParty: {
          ...eachInvlParty,
          beneficiaryDetails: {
            ...singleBenef,
            beneficiaryType
          }
        }
      };
    }
  });
  return benInvolObj;
};

export const loopThroughBenficiary = (
  benefDetails,
  involvedParties,
  beneficiaryType
) => {
  const beneInfo = [];
  _.map(benefDetails, eachBen => {
    const benInvolObj = getInvolvePartyDetails(
      involvedParties,
      eachBen,
      beneficiaryType
    );
    if (benInvolObj) {
      beneInfo.push(benInvolObj);
    }
  });
  return beneInfo;
};

export const policyChangeBeneInfo = (beneficiaryDetails, involvedParties) => {
  let changeBeneInfo = [];
  let priBenInfo = [];
  let secBenInfo = [];
  if (beneficiaryDetails && involvedParties) {
    if (
      beneficiaryDetails.primaryBeneficiaries &&
      beneficiaryDetails.primaryBeneficiaries.length > 0
    ) {
      priBenInfo = loopThroughBenficiary(
        beneficiaryDetails.primaryBeneficiaries,
        involvedParties,
        'Primary'
      );
    }
    // secondary Beneficiaries
    if (
      beneficiaryDetails.secondaryBeneficiaries &&
      beneficiaryDetails.secondaryBeneficiaries.length > 0
    ) {
      secBenInfo = loopThroughBenficiary(
        beneficiaryDetails.secondaryBeneficiaries,
        involvedParties,
        'Secondary'
      );
    }
    changeBeneInfo = {
      primaryBeneficiaries: priBenInfo,
      secondaryBeneficiaries: secBenInfo
    };
  }

  return changeBeneInfo;
};

export const getValueFormKey = key => {
  if (key) {
    const data = key.toString();
    return _.trim(data, ' ').replace(' ', '');
  }
  return '';
};

const checkStatusItem = (eachInvlParty, item) => {
  let status = false;
  const checkDocTypes = ['I', 'P', 'M', 'X'];
  if (
    getValueFormKey(eachInvlParty?.givenName) === '' ||
    getValueFormKey(eachInvlParty?.lastName) === '' ||
    getValueFormKey(eachInvlParty?.rolePlayerIdentity?.documentNumber) === '' ||
    getValueFormKey(eachInvlParty?.rolePlayerIdentity?.documentType) === '' ||
    (_.includes(
      checkDocTypes,
      getValueFormKey(
        eachInvlParty?.rolePlayerIdentity?.documentType
      ).toUpperCase()
    ) &&
      getValueFormKey(eachInvlParty?.rolePlayerIdentity?.documentNumber)
        .trim()
        .replace(' ', '')
        .toUpperCase()
        .startsWith('ICS')) ||
    getValueFormKey(item?.allocationPercentage) === '' ||
    getValueFormKey(item?.beneficiaryRole) === ''
  ) {
    status = true;
  }
  return status;
};

export const getMissingInfo = (details, item) => {
  let status = false;
  details.involvedParties.forEach(eachInvlParty => {
    if (
      eachInvlParty.involvedPartyRole.findIndex(
        eachRole => eachRole === 'Beneficiary'
      ) !== -1 &&
      eachInvlParty?.involvedPartyIdentificationNumber ===
        item?.involvedPartyIdentificationNumber
    ) {
      status = checkStatusItem(eachInvlParty, item);
    }
    return eachInvlParty;
  });
  return status;
};

export const getNonPersonalRelationshipStatus = details => {
  let status = false;
  if (details && details.length > 0) {
    details.forEach(data => {
      const relation = _.trim(data.beneficiaryRole, ' ').toUpperCase();
      if (
        relation === 'BUSINESS' ||
        relation === 'CHARITY' ||
        relation === 'CHURCH'
      ) {
        status = true;
      }
    });
  }
  return status;
};

export const handleClickChangeBeneficiary = (
  e,
  changeBeneficiaryService,
  encodedProductId,
  irrevocableAssigned,
  assigned,
  isNonPersonalRelation
) => {
  e.preventDefault();
  const linkUrl = getModifyUpdateJourneyUrl(
    changeBeneficiaryService,
    encodedProductId
  );

  if (
    !irrevocableAssigned &&
    !assigned &&
    linkUrl !== '' &&
    !isNonPersonalRelation
  ) {
    window.location.href = linkUrl;
    window.localStorage.setItem('selectedBeneficiaryType', 'primary');
  }
};
export const LIFE_PRODUCT_CATEGORY = 'life';

//   This is the relationship list which
//   is used to show the relationship value by matching copy kitten and api response.
//  here 'value' refers the api response

export const relationshipList = [
  {
    label: 'relation_field19',
    value: 'Self'
  },
  {
    label: 'relation_field1',
    value: 'Adoptive Parent'
  },
  {
    label: 'relation_field4',
    value: 'Child(Adopted)'
  },
  {
    label: 'relation_field7',
    value: 'Daughter'
  },
  {
    label: 'relation_field8',
    value: 'Executor'
  },
  {
    label: 'relation_field9',
    value: 'Father'
  },
  {
    label: 'relation_field10',
    value: 'Fiance, Fiancee'
  },
  {
    label: 'relation_field11',
    value: 'Friend'
  },
  {
    label: 'relation_field12',
    value: 'GrandChild'
  },
  {
    label: 'relation_field13',
    value: 'GrandParent'
  },
  {
    label: 'relation_field14',
    value: 'Husband'
  },
  {
    label: 'relation_field15',
    value: 'Legal Guardian'
  },
  {
    label: 'relation_field30',
    value: 'Mother'
  },
  {
    label: 'relation_field16',
    value: 'Non cohabiting Partner'
  },
  {
    label: 'relation_field20',
    value: 'Sibling'
  },
  {
    label: 'relation_field21',
    value: 'Son'
  },
  {
    label: 'relation_field23',
    value: 'Step Brother'
  },
  {
    label: 'relation_field24',
    value: 'Step Child'
  },
  {
    label: 'relation_field25',
    value: 'Step Parent'
  },
  {
    label: 'relation_field26',
    value: 'Step Sister'
  },
  {
    label: 'relation_field28',
    value: 'Wife'
  },
  {
    label: 'relation_field29',
    value: 'Cohabiting Partner'
  },

  {
    label: 'relation_field31',
    value: 'Business'
  },
  {
    label: 'relation_field32',
    value: 'Children'
  },
  {
    label: 'relation_field33',
    value: 'Charity'
  },
  {
    label: 'relation_field34',
    value: 'Church'
  },
  {
    label: 'relation_field35',
    value: 'Other'
  },
  {
    label: 'relation_field36',
    value: 'Parents'
  },
  {
    label: 'relation_field37',
    value: 'Spouse'
  },
  {
    label: 'relation_field38',
    value: 'Trustee'
  }
];

export const relationFinder = beneficiaryRole =>
  _.result(
    _.find(
      relationshipList,
      node => node.value.toUpperCase() === beneficiaryRole.toUpperCase()
    ),
    'label'
  ) ?? beneficiaryRole;

export const conditionForChangeBeneficiary = (prodCat, subProductFamilies) =>
  prodCat &&
  subProductFamilies.findIndex(
    eachCat =>
      eachCat.productCategoryName.toLowerCase() === prodCat.toLowerCase() &&
      eachCat.superFamily.toLowerCase() === LIFE_PRODUCT_CATEGORY
  );

export const conditionForentityCode = entityCode => {
  return isApplicationStubbed()
    ? true
    : entityCode &&
        entityCode !== undefined &&
        entityCode.replace(/ /g, '') !== '' &&
        entityCode.replace(/ /g, '') !== COMPANY_1_ENTITY_CODE;
};
