import React from 'react';
import PropTypes from 'prop-types';
import iconRight from '../../assets/images/Icon_right.svg';
import estatment from '../../assets/images/estatment.svg';
import ClaimWrapper from './ClaimWrapper';
import { trackEvents } from '../../utils/tealiumUtils';
import { captureCustomerGroup } from '../sectionUtils/SectionsUtils';
import TooltipWrapper from '../../common/tooltipWrapper/TooltipWrapper';
import { LinkTooltipWrapper } from '../../estatement/estatementCommonStyle';

const openLink = linkInfo => {
  if (linkInfo) {
    Object.assign(document.createElement('a'), {
      target: linkInfo.target,
      href: linkInfo.url,
      rel: 'noopener noreferrer nofollow'
    }).click();
  }
};

const EstatementAndAdvices = (props, context) => {
  const {
    intl: { locale, formatMessage }
  } = context;
  const linkRef = React.createRef();
  const { customer, genericConfig, estatementsAdvices } = props;
  const urlToRedirect = estatementsAdvices.link[locale];
  const customerGroup = captureCustomerGroup(customer, genericConfig);
  return (
    <LinkTooltipWrapper>
      <ClaimWrapper
        id="estatement_and_advices"
        ariaLabel={formatMessage({ id: 'estatementAndAdvices' })}
        onClick={e => {
          trackEvents({
            eventName: 'estatementAndAdvices',
            eventContent: 'view estatement and eadvices',
            rawDataLayer: '17096v1',
            customerGroup
          });
          openLink({
            url: urlToRedirect,
            target: '_self'
          });
          e.preventDefault();
        }}
        altText="edit-icon"
        iconSrc={estatment}
        linkLabel={formatMessage({ id: 'estatementAndAdvices' })}
        altTextIconRight="right-icon"
        iconRight={iconRight}
        linkRef={linkRef}
      />
      <TooltipWrapper
        id="estatementAndAdvicesTooltip"
        labelContent="estatementAndAdvicesTooltipDisclaimer"
        contentKey="estatementAndAdvicesTooltipDisclaimer"
        maxWidth={350}
        sectionHeading="policyOverview"
      />
    </LinkTooltipWrapper>
  );
};

export default EstatementAndAdvices;

EstatementAndAdvices.contextTypes = {
  intl: PropTypes.object.isRequired
};

EstatementAndAdvices.propTypes = {
  estatementsAdvices: PropTypes.array.isRequired,
  customer: PropTypes.object.isRequired,
  genericConfig: PropTypes.object.isRequired
};
