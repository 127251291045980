import React from 'react';
import * as _ from 'lodash';
import md5 from 'crypto-js/md5';
import AES from 'crypto-js/aes';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _camelCase from 'lodash/camelCase';
import { TagValue } from '../common/styles/commonStyle';
import { summaryWarningMessageResolver } from '../policysummary/summaryUtils/summaryWarningMessageUtils';
import {
  setCookie,
  checkCookie,
  setItemInLocalStorage,
  getItemInLocalStorage
} from './cookieUtils';
import isCustomerWhiteListed from './customerUtils';
import { servicingLinkAvailable } from '../policydetail/sectionUtils/FundUtils';
import {
  conditionForChangeBeneficiary,
  policyChangeBeneInfo
} from './BeneficiaryUtils';

const checkOptOutIndicator = optOutIndicator => optOutIndicator === false;

/**
 * This function would be responsible for:
 * 1) Firstly creating a localStorage with Cookie identifier.
 * 2) If the cookie is not present, need to delete the localStorage.
 * 3) Create a cookie for this policy, add cookie identifier in localstorage and also add relevant information for caching to another localStorage.
 */
const cacheForServicing = function cacheForServicing(
  policyInfo,
  customerIdentifier,
  customerSegment,
  customerPermId
) {
  // const { customerIdentifier, docId, hkId } = customerInfo;
  const {
    policyOverview: {
      policyNumber,
      encodePolicyNumber,
      planCode,
      optOutIndicator,
      riskMismatchIndicator,
      planName,
      policyCurrency,
      entityCode,
      productCategory
    },
    contactDetails,
    funds,
    beneficiaryDetails,
    involvedParties,
    policyServicingOptions
  } = policyInfo;

  const fundDetails = _.get(funds, 'fundDetails', []);

  const coreFund =
    (checkOptOutIndicator(optOutIndicator) && _.head(fundDetails)) || {};

  const changeBeneInfo = policyChangeBeneInfo(
    beneficiaryDetails,
    involvedParties
  );

  /**
   * All the information that needs to be cached
   */
  const cacheForServicingObj = {
    policyNumber,
    planName,
    policyCurrency,
    encodePolicyNumber,
    planCode,
    optOutIndicator,
    coreFund,
    riskMismatchIndicator,
    contactDetails,
    customerIdentifier,
    policyServicingOptions,
    customerSegment,
    changeBeneInfo,
    entityCode: entityCode ?? '',
    involvedParties:
      involvedParties && involvedParties.length > 0 ? involvedParties : [],
    productCategory
  };
  /**
   * Set the cookie, with name as the encodeID,
   * This would be used in the servicing journey to verify if the localstorage is obsolete or otherwise
   */
  setCookie(`validity_${md5(encodePolicyNumber).toString()}`, true);
  /**
   * cacheEncodeIds array of encodedPolicyIds for which we have cached data.
   */
  const cacheEncodeIds = getItemInLocalStorage('cacheIdentifier') || [];
  /**
   * Add this encodedPolicyId to the "cacheIdentifier" localstorage if not already present.
   *
   */
  if (!_.includes(cacheEncodeIds, encodePolicyNumber)) {
    cacheEncodeIds.push(encodePolicyNumber);
  }

  const encodedCachedData = AES.encrypt(
    JSON.stringify(cacheForServicingObj),
    customerPermId
  ).toString();

  const cacheEncodeIdsUpdated = [];
  _.each(cacheEncodeIds, cacheEncodeId => {
    if (!checkCookie(`validity_${md5(cacheEncodeId).toString()}`)) {
      /**
       * Need to delete  from 2 localStorage
       */
      localStorage.removeItem(cacheEncodeId);
    } else {
      if (cacheForServicingObj.encodePolicyNumber === cacheEncodeId) {
        setItemInLocalStorage(cacheEncodeId, encodedCachedData);
      }
      cacheEncodeIdsUpdated.push(cacheEncodeId);
    }
  });
  setItemInLocalStorage('cacheIdentifier', cacheEncodeIdsUpdated);
};
/**
 *  Provide the copy label for a particular status code
 * @param policyStatusCode: Should be passed with 2 character strings.
 * @returns: label definition present in the copy json(policy_status_IF, policy_status_LA)
 */
export const getPolicyStatusCopyLabel = (policyStatusCode = null) => {
  return policyStatusCode ? `policy_status_${policyStatusCode}` : null;
};

export const eWelcomePackEnabled = customerPolicy => {
  let eWelcomePackFlag;
  let acknowledgeFlag;
  let encodePolicyNumber;
  let planName;
  let coolingOffDateFlag;

  if (customerPolicy && customerPolicy.policyNumber) {
    ({
      eWelcomePackFlags: { eWelcomePackFlag, acknowledgeFlag } = {},
      encodePolicyNumber,
      planName,
      coolingOffDateFlag
    } = customerPolicy);
  } else if (customerPolicy && customerPolicy.policyDetails) {
    ({
      eWelcomePackFlags: { eWelcomePackFlag, acknowledgeFlag } = {},
      policyOverview: { encodePolicyNumber, planName, coolingOffDateFlag } = {}
    } = customerPolicy.policyDetails);
  }

  if (
    eWelcomePackFlag === 'Y' &&
    acknowledgeFlag !== 'Y' &&
    coolingOffDateFlag !== false
  ) {
    return {
      showWelcomePack: true,
      encodePolicyNumber,
      planName,
      customerPolicy
    };
  }
  return false;
};

export const preparePoliciesStore = (
  subProductCategories,
  productFamilyOrder,
  summaryResponse
) => {
  const stackedSuperCategoryProducts = {};
  let policiesDataStore = {};
  const eWelcomePack = [];
  /**
   * To form the stacking structure on the Summary Page, need to have a data structure of the format :
   *
   * {product family(eg. Life, Savings and Investmants etc) : [product categories under it], superC2 : [product categories under it]}
   *
   * Iterating the summary response json for product families, finding their super categories
   * and forming the above mentioned data stucture.
   *
   * In case of any absence of product families for any particular super family, the particular
   * stack won't be visible on the UI. Basically, not adding that super category to the above data structure.
   */

  /** Looping through Summary API response,
   * mapping each customer products to product configurator to find respective super Categories to stack in
   * */

  _.map(summaryResponse.policies, customerPolicy => {
    /**
     * Validating if the customer's product is configured in the Product Configurator.
     * If the configuration is found, finding out it's super category, forming an object and stacking matching products under it.
     * Object Name - stackedSuperCategoryProducts - {SC1 : [..product list], SC2 : [..product list]}
     */
    if (
      _.some(
        subProductCategories,
        configuredProduct =>
          configuredProduct.productCategoryName ===
          customerPolicy.productCategory
      )
    ) {
      /**
       * Finding Super category stack names from the APIs response itself i.e. from productFamily field in policies response
       */
      const superCategoryFromAPI = _.camelCase(customerPolicy.productFamily);

      /**
       * If a super Category SC is already present in the object, then simply pushing the product in it's array.
       * If a super Category SC is not present in the object, then creating a new property and adding an array of it's related product.
       */

      if (stackedSuperCategoryProducts[superCategoryFromAPI]) {
        stackedSuperCategoryProducts[superCategoryFromAPI].push(customerPolicy);
      } else {
        stackedSuperCategoryProducts[superCategoryFromAPI] = [customerPolicy];
      }
    }
    const eWelcome = eWelcomePackEnabled(customerPolicy);
    if (eWelcome && eWelcome.showWelcomePack) {
      eWelcomePack.push(eWelcome);
    }
  });

  // updatedProductFamilyOrder is subset of productFamilyOrder but without empty policies
  const updatedProductFamilyOrder = _.intersection(
    productFamilyOrder,
    Object.keys(stackedSuperCategoryProducts)
  );

  const policyByFamily = {};
  const policyMappedToEncodePolicyId = {};
  _.each(productFamilyOrder, familyName => {
    policyByFamily[familyName] = [];
    _.each(stackedSuperCategoryProducts[familyName], policy => {
      policyByFamily[familyName].push(policy.encodePolicyNumber);
      policyMappedToEncodePolicyId[policy.encodePolicyNumber] = policy;
    });
  });
  /* warningMessageResolver will return warningMessage array which contains
   label: Overdue/APL to get content from copy and value: boolean for its visibility into UI
   for ex. [{label: "aplAlertIndicatorLabel", value: true}]
*/
  const summaryWarningsPageLevel = summaryWarningMessageResolver(
    policyMappedToEncodePolicyId
  );
  policiesDataStore = {
    eWelcomePack,
    byFamily: policyByFamily,
    byEncodeId: policyMappedToEncodePolicyId,
    productFamilyOrder: updatedProductFamilyOrder,
    totalPolicyCount: summaryResponse && summaryResponse.policies.length,
    summaryWarningsPageLevel
  };

  return policiesDataStore;
};

const isEndowmentMaturityChecker = function isEndowmentMaturityChecker(
  productCategory,
  superCategory,
  field6,
  field9
) {
  return (
    productCategory === 'Endowment' &&
    superCategory !== 'life' &&
    !!field9.value &&
    !field6.value
  );
};
const isAnnuityChecker = function isAnnuityChecker(
  productCategory,
  field6,
  field10,
  field11,
  field13
) {
  return (
    productCategory === 'Annuity' &&
    (!!field6.value || !!field11.value || !!field10.value) &&
    !!field13.value
  );
};
const isBeforeAnnuityChecker = function isBeforeAnnuityChecker(
  field6,
  field11,
  field13
) {
  return field13.value === 'BF' && (!!field6.value || !!field11.value);
};
const isAnnuityPayoutStartedChecker = function isAnnuityPayoutStartedChecker(
  field6,
  field10,
  field13
) {
  return field13.value === 'AF' && (!!field6.value || !!field10.value);
};
const isAnnuityPayoutNotStartedChecker = function isAnnuityPayoutNotStartedChecker(
  field6,
  field11,
  field13
) {
  return field13.value === 'ON' && (!!field6.value || !!field11.value);
};

/**
 *
 * @param {Object} productFieldsObject : Array of field orders coming from product Configuration
 * @param {String} superCategory : Life or GI
 * @param {String} productCategory : Product Category i.e. Annuity, Endowment etc.
 * @description : This method is used to process the next action message for summary cards
 *
 */

export const getSummaryCardActionData = (
  productFieldsObject,
  superCategory,
  productCategory
) => {
  const {
    field6 = {},
    field9 = {},
    field10 = {},
    field11 = {},
    field13 = {}
  } = productFieldsObject;

  let currencyCode;
  let date;
  let splitOneMessageKey;
  let splitTwoMessageKey;

  /**
   * priority is given to premiumPaymentDueDate i.e field6 , if field6 not present show maturity date
   */
  const isEndowmentMaturity = isEndowmentMaturityChecker(
    productCategory,
    superCategory,
    field6,
    field9
  ); // policyMaturityDate & premiumPaymentDueDate

  /**
   * Checking for Annuity product and checking for other API Values like :
   * premiumPaymentDueDate - field6
   * annuityNextPaymentDate - field10
   * annuityPaymentStartDate - field11
   */

  const isAnnuity = isAnnuityChecker(
    productCategory,
    field6,
    field10,
    field11,
    field13
  );

  // PremiumAmountDue and due date
  const isPremiumPolicy = () => {
    return field6.value;
  };

  if (isEndowmentMaturity) {
    splitTwoMessageKey = 'summaryPolicyMaturity';
    date = field9.value;
  } else if (isAnnuity) {
    /**
     * For Annuity phase BF : Giving preference to premiumPaymentDueDate over annuityPaymentStartDate.
     */

    const isBeforeAnnuity = isBeforeAnnuityChecker(field6, field11, field13);
    /**
     * For Annuity phase AF : Giving preference to premiumPaymentDueDate over annuityNextPaymentDate.
     */

    const isAnnuityPayoutStarted = isAnnuityPayoutStartedChecker(
      field6,
      field10,
      field13
    );

    /**
     * For Annuity phase ON : Giving preference to premiumPaymentDueDate over annuityPaymentStartDate.
     */

    const isAnnuityPayoutNotStarted = isAnnuityPayoutNotStartedChecker(
      field6,
      field11,
      field13
    );

    if (field6.value) {
      splitOneMessageKey = 'summaryPremiumDue';
      date = field6.value;
    } else if (
      (isBeforeAnnuity || isAnnuityPayoutNotStarted) &&
      field11.value
    ) {
      splitOneMessageKey = 'summaryAnnuityPayoutNotStarted';
      date = field11.value;
    } else if (isAnnuityPayoutStarted && field10.value) {
      splitOneMessageKey = 'summaryAnnuityPayoutStarted';
      date = field10.value;
    } else {
      splitTwoMessageKey = _.camelCase(
        `nextActionProductTagLine${productCategory}`
      );
    }
  } else if (isPremiumPolicy()) {
    splitOneMessageKey = 'summaryPremiumDue';
    date = field6.value;
  } else {
    splitTwoMessageKey = _.camelCase(
      `nextActionProductTagLine${productCategory}`
    );
  }

  return {
    currencyCode,
    date,
    splitOneMessageKey,
    splitTwoMessageKey
  };
};

/**
 * Amending the details  API response to have some additional fields
 * 1) Assignee roles is present - hasAssigneeRole - boolean
 * 2) planCode is VL1 or CSA - hasPlanCodeVL1CSA - boolean
 */
export const additionalFieldInDetails = policyDetailsResponseData => {
  const policyDetailsToAmend = policyDetailsResponseData;
  const {
    policyDetails: { involvedParties, policyOverview }
  } = policyDetailsToAmend;

  const hasAssigneeRole = _.find(involvedParties, function(involvedParty) {
    return _.includes(involvedParty.involvedPartyRole, 'Assignee');
  });

  const hasPlanCodeVL1CSA =
    policyOverview.planCode === 'VL1' || policyOverview.planCode === 'CSA';

  policyOverview.hasAssigneeRole = !!hasAssigneeRole;
  policyOverview.hasPlanCodeVL1CSA = !!hasPlanCodeVL1CSA;

  policyOverview.optOutIndicator =
    policyOverview.optOutIndicator !== undefined
      ? policyOverview.optOutIndicator
      : null;

  return policyDetailsToAmend;
};

export const preparePolicyDetailsStore = (
  encodePolicyNumber,
  policyDetailsResponseData,
  policiesByEncodeId = {},
  functionEligibility = null,
  customerIdentifier,
  customerSegment,
  customerPermId,
  subProductFamilies
) => {
  const policyList = policiesByEncodeId;
  const policySummary = policyList && policyList[encodePolicyNumber];
  const policyDetailsData = additionalFieldInDetails(policyDetailsResponseData);
  if (policySummary) {
    policySummary.policyDetails = policyDetailsData.policyDetails;
    policyList[encodePolicyNumber] = policySummary;
  } else {
    policyList[encodePolicyNumber] = policyDetailsData;
  }
  /**
   * Pushing all the information required for servicing journey in the localStorage and also cookie indicator associated with this for expiry.
   * If customer is white listed and transfer of funds flow is supported(checking Assinee role, plan code VL1 and any pending transaction)
   */

  const flowForTransferOfFunds = servicingLinkAvailable(
    'TransferOfFunds_Link',
    policyDetailsData.policyDetails
  );
  const productLife = conditionForChangeBeneficiary(
    policyDetailsData?.policyDetails?.policyOverview?.productCategory,
    subProductFamilies
  );
  const isCacheForServicing =
    (isCustomerWhiteListed('TransferOfFunds_Web', functionEligibility) &&
      !flowForTransferOfFunds) ||
    productLife !== -1;
  isCacheForServicing &&
    cacheForServicing(
      policyDetailsData.policyDetails,
      customerIdentifier,
      customerSegment,
      customerPermId
    );
  return { byEncodeId: policyList };
};

export const getFieldDataFromConfig = (
  currentPolicyDetail,
  fieldConfigInstance,
  shouldCheckFieldKey = true,
  fieldNameKey = 'fieldName',
  summaryResponse = null
) => {
  let fieldIdentifierKey;
  if (currentPolicyDetail) {
    if (!shouldCheckFieldKey) {
      return _get(currentPolicyDetail, fieldConfigInstance.fieldParent);
    }
    if (fieldConfigInstance.fieldParent) {
      fieldIdentifierKey = `${fieldConfigInstance.fieldParent}.${fieldConfigInstance[fieldNameKey]}`;
    } else {
      fieldIdentifierKey = `${fieldConfigInstance[fieldNameKey]}`;
    }
    return _get(currentPolicyDetail, fieldIdentifierKey);
  }
  if (!shouldCheckFieldKey) {
    return _get(summaryResponse, fieldConfigInstance.fieldParent);
  }
  fieldIdentifierKey = `${fieldConfigInstance[fieldNameKey]}`;
  return _get(summaryResponse, fieldIdentifierKey);
};

export const getDependentFieldDataFromConfig = (
  currentPolicyDetail,
  fieldConfigInstance,
  shouldCheckFieldKey = true,
  fieldNameKey = 'dependentFieldName',
  summaryResponse = null
) => {
  let fieldIdentifierKey;
  if (currentPolicyDetail) {
    if (!shouldCheckFieldKey) {
      return _get(
        currentPolicyDetail,
        fieldConfigInstance.dependentFieldParent
      );
    }
    if (fieldConfigInstance.dependentFieldParent) {
      fieldIdentifierKey = `${fieldConfigInstance.dependentFieldParent}.${fieldConfigInstance[fieldNameKey]}`;
    } else {
      fieldIdentifierKey = `${fieldConfigInstance[fieldNameKey]}`;
    }
    return _get(currentPolicyDetail, fieldIdentifierKey);
  }
  if (!shouldCheckFieldKey) {
    return _get(summaryResponse, fieldConfigInstance.dependentFieldParent);
  }
  fieldIdentifierKey = `${fieldConfigInstance[fieldNameKey]}`;
  return _get(summaryResponse, fieldIdentifierKey);
};

export const findPolicyCheckValue = (
  checkType,
  openedPolicy,
  policyByEncodeId,
  checkFalseIndicator
) => {
  const { summaryFieldParent, detailsFieldParent, fieldName } = checkType;
  if (openedPolicy && openedPolicy.length > 0) {
    const summaryCheck = _get(
      policyByEncodeId[openedPolicy],
      summaryFieldParent
    );
    const detailCheck = _get(
      policyByEncodeId[openedPolicy],
      `${detailsFieldParent}.${fieldName}`
    );
    if (summaryCheck) {
      return summaryCheck;
    }
    if (detailCheck) {
      return detailCheck;
    }
    if (checkFalseIndicator) {
      if (summaryCheck === false || detailCheck === false) {
        return false;
      }
      return null;
    }
  }
  return false;
};

export const getMedicalAndCriticalIllnessTag = (
  tagForMedical,
  tagForCriticalIllness,
  tagsFromConfig
) => {
  if (tagForMedical) {
    return _.slice(tagsFromConfig, 2, -1);
  }
  if (tagForCriticalIllness) {
    return _.slice(tagsFromConfig, 3);
  }
  return _.slice(tagsFromConfig, 2);
};

export const getTagsByCopyBasedonProductCode = (
  tagsFromConfig,
  productCode,
  policyTagsMedicalAndCIConfig,
  policyInfo
) => {
  let currentPolicyPlanCode = '';
  let tagForMedical = false;
  let tagForCriticalIllness = false;
  const {
    policyTagsMedicalAndCI: { planCodesUnderMedical, planCodesUnderCI }
  } = policyTagsMedicalAndCIConfig;
  currentPolicyPlanCode =
    policyInfo &&
    policyInfo.policyOverview &&
    policyInfo.policyOverview.planCode;
  tagForMedical = _.includes(planCodesUnderMedical, currentPolicyPlanCode);
  tagForCriticalIllness = _.includes(planCodesUnderCI, currentPolicyPlanCode);

  if (productCode === 'MED') {
    return getMedicalAndCriticalIllnessTag(
      tagForMedical,
      tagForCriticalIllness,
      tagsFromConfig
    );
  }
  return _.slice(tagsFromConfig, 2);
};

export const tagGenerator = function tagGenerator(
  matchedConfiguredProduct,
  policyInfo,
  selectedLocale,
  langObj,
  getContentInSpecificLang,
  formatMessage,
  summaryResponse,
  policyTagsMedicalAndCIConfig
) {
  const { tags: tagsFromConfig = [], productCode } = matchedConfiguredProduct;
  const tagsByData = _.slice(tagsFromConfig, 0, 2);
  const tagsByCopy = getTagsByCopyBasedonProductCode(
    tagsFromConfig,
    productCode,
    policyTagsMedicalAndCIConfig,
    policyInfo
  );
  const validTagsByCopy = _.filter(tagsByCopy, tag => {
    return getContentInSpecificLang(tag, selectedLocale, langObj);
  });
  const tagsByCopyComponents = _map(validTagsByCopy, (tag, index) => {
    const styleUpdate = validTagsByCopy.length - index > 1;
    return (
      <TagValue styleUpdate={styleUpdate} id={tag} key={tag}>
        {formatMessage({ id: tag })}
      </TagValue>
    );
  });
  const lengthOfCopyComponentTags = _.compact(tagsByCopyComponents).length;
  const tagsByDataComponents = _map(tagsByData, (tag, index) => {
    const styleOnCount = lengthOfCopyComponentTags ? 0 : 1;
    const styleUpdate = tagsByData.length - index > styleOnCount;
    if (tag === 'policyCurrency') {
      let tagValueFromAPI = null;
      if (policyInfo) {
        tagValueFromAPI =
          policyInfo &&
          policyInfo.policyOverview &&
          policyInfo.policyOverview.policyCurrency;
      } else {
        tagValueFromAPI = summaryResponse && summaryResponse.policyCurrency;
      }
      return (
        tagValueFromAPI &&
        getContentInSpecificLang(
          `${tagValueFromAPI}_CURRENCY`,
          selectedLocale,
          langObj
        ) && (
          <TagValue styleUpdate={styleUpdate} id={tag} key={tag}>
            {formatMessage({
              id: `${tagValueFromAPI}_CURRENCY`
            })}
          </TagValue>
        )
      );
    }
    if (tag === 'productFamily') {
      let tagValueFromAPI = null;
      if (policyInfo) {
        tagValueFromAPI =
          policyInfo &&
          policyInfo.policyOverview &&
          policyInfo.policyOverview.productFamily;
      } else {
        tagValueFromAPI = summaryResponse && summaryResponse.productFamily;
      }
      return (
        tagValueFromAPI &&
        getContentInSpecificLang(
          `${_camelCase(tagValueFromAPI)}_PRODUCTFAMILY_TAG`,
          selectedLocale,
          langObj
        ) && (
          <TagValue styleUpdate={styleUpdate} id={tag} key={tag}>
            {formatMessage({
              id: `${_camelCase(tagValueFromAPI)}_PRODUCTFAMILY_TAG`
            })}
          </TagValue>
        )
      );
    }
    return null;
  });

  return _.concat(tagsByDataComponents, tagsByCopyComponents);
};

export function checkFieldData(elements) {
  let isFieldDataAvailable = false;
  let isObjectValueAbsent = false;

  /**
   * Modifying the way of identifying data type.
   * Using 'constructor.name' as array data type was needed to be catered separately as well
   * to handle cases of involvedParties(array data structure) and Payment section's field 'Payer Name'.
   */

  const validateAmountField = fieldData => {
    return fieldData.amount === undefined || fieldData.currency === undefined;
  };

  const validateObject = value => value === undefined || value === null;

  elements.map(function fieldDataMapper(element) {
    if (element.props && element.props.fieldData) {
      const { fieldType, fieldData } = element.props;
      if (fieldData.constructor.name === 'Object') {
        const values = Object.values(fieldData);
        values.map(function nullFieldChecker(objectValue) {
          if (validateObject(objectValue)) {
            isObjectValueAbsent = true;
          }
          return isObjectValueAbsent;
        });
        if (fieldType === 'amount') {
          isObjectValueAbsent = validateAmountField(fieldData);
        }
      } else if (fieldData.constructor.name === 'Array') {
        isObjectValueAbsent = fieldData.length < 1;
      } else if (fieldData) {
        /*
         If field data is not object or not type array but still it has value
         in that case set the isObjectValueAbsent to false
        */
        isObjectValueAbsent = false;
      }
      isFieldDataAvailable = isObjectValueAbsent ? isFieldDataAvailable : true;
    }
    return isFieldDataAvailable;
  });
  return isFieldDataAvailable;
}

/**
 * @argument currentPolicy object the details object is passed
 * @argument digiserviceObj object that has the  confguration url and field
 * @argument configurations array has the mappings of the planType to pdf document
 * @returns  object e.g. {url: "https...", target:"_blank"}
 */
export const getDigiServiceLink = function getDigiServiceLink(
  currentPolicy,
  digiserviceObj,
  configurations
) {
  const { url, field, target, type } = digiserviceObj;
  const policyFieldValue = _get(currentPolicy, field);
  const baseUrl = window.location.origin;
  if (policyFieldValue) {
    if (type === 'service') {
      const serviceLink = `${baseUrl}${url}${policyFieldValue}`;
      return {
        url: serviceLink,
        target
      };
    }
    if (type === 'instructionHistory') {
      const serviceLink = `${baseUrl}${url}${policyFieldValue}`;
      return {
        url: serviceLink,
        target,
        type
      };
    }
    const { downloadIdentifier = '' } = _.find(
      configurations,
      function matchPlanType(configuration) {
        return configuration.planTypeValue === policyFieldValue;
      }
    );
    if (downloadIdentifier) {
      const serviceLink = `${url[downloadIdentifier]}`;
      return {
        url: serviceLink,
        target: '_blank'
      };
    }
  }
  return null;
};

export function getFieldValue(fieldDataObj, fieldName) {
  return !_.isEmpty(fieldDataObj) && fieldDataObj[`${fieldName}`];
}

export function getObjectResponse(response, dependentField) {
  if (response[dependentField] && response[dependentField].length) {
    return response;
  }
  return null;
}

const checkEmptyObject = value => {
  return typeof value === 'object' && !_.isEmpty(value);
};

const checkFieldDataElement = element => {
  return (
    element.props &&
    element.props.fieldData &&
    element.props.fieldData.constructor.name === 'Array'
  );
};
export function childProtectionRightSectionData(elements) {
  let isFieldDataAvailable = false;
  let fieldValue;
  let isFieldValuePresent = false;

  const getValueTypeString = (response, fieldName) => {
    if (typeof response[fieldName] === 'string') {
      isFieldValuePresent = true;
      return response;
    }
    return null;
  };
  /**
   * Modifying the way of identifying data type.
   * Using 'constructor.name' as array data type was needed to be catered separately as well
   * to handle cases of involvedParties(array data structure) and Payment section's field 'Payer Name'.
   */

  elements.map(function fieldDataMapper(element) {
    if (checkFieldDataElement(element)) {
      const values = element.props.fieldData;
      const { fieldName } = element.props;
      const { dependentField } = element.props;
      const fieldDataObj = _.find(values, response => {
        if (response[fieldName]) {
          if (checkEmptyObject(response[fieldName])) {
            return getObjectResponse(response, dependentField);
          }
          return getValueTypeString(response, fieldName);
        }
        return null;
      });

      fieldValue = getFieldValue(fieldDataObj, fieldName);
      if (!fieldValue && !isFieldValuePresent) {
        isFieldDataAvailable = false;
      } else {
        isFieldDataAvailable = true;
      }
    }
    return isFieldDataAvailable;
  });
  return isFieldDataAvailable;
}

export function getFieldDataObj(fieldData, fieldName) {
  return _.find(fieldData, response => {
    if (response[fieldName] && response[fieldName].length) {
      return response;
    }
    return null;
  });
}

export function redirectInstructionHistoryToP2G(serviceOption, superFamily) {
  const isServicingOptionInsHistory = serviceOption === 'instructionHistory';
  return isServicingOptionInsHistory && superFamily === 'gi';
}

export function checkIfInstructionLinkWithSuperFamily(
  superFamily,
  digiserviceObj
) {
  const { type = null } = digiserviceObj;
  if (redirectInstructionHistoryToP2G(type, superFamily)) {
    return false;
  }
  return true;
}

export function overrideLableWhen(currentPolicyDetail, fieldConfig) {
  const { overideLabelWhen } = fieldConfig;
  if (overideLabelWhen) {
    const fieldConfigTmp = _.cloneDeep(fieldConfig);
    const [condition, overrideValue] = overideLabelWhen.split('|');
    condition.split(',').map(expression => {
      const exp = expression.split('=');
      if (_.get(currentPolicyDetail, exp[0]) === exp[1]) {
        fieldConfigTmp.fieldLabel = overrideValue;
      }
      return expression;
    });
    return fieldConfigTmp;
  }
  return fieldConfig;
}

export default preparePoliciesStore;
