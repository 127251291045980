import PropTypes from 'prop-types';

/**
 * Wrapper component to show child component pass condition in props is true
 * TODO: implement Else condition too
 * @param {Children and If(boolean)} props
 */
const ShowIf = props => {
  const { condition } = props;
  if (condition === true) {
    return props.children;
  }
  return null;
};

ShowIf.propTypes = {
  condition: PropTypes.bool.isRequired
};

export default ShowIf;
