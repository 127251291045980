import _ from 'lodash';

const DEATH_BENEFIT_SETTLEMENT_OPTION_LIST = ['A', 'B'];
const IDENTITY_DETAILS_VALUES_TO_SUPPRESS = ['na', '-'];

export const canSuppressBeneficiaries = currentPolicyDetail =>
  _.includes(
    DEATH_BENEFIT_SETTLEMENT_OPTION_LIST,
    currentPolicyDetail?.policyBenefitDetails?.deathBenefitDetails
      ?.deathBenefitSettlementOption?.key
  );

const checkDocumentNumber = eachInvlParty => {
  const {
    rolePlayerIdentity: { documentNumber }
  } = eachInvlParty;
  return _.includes(
    IDENTITY_DETAILS_VALUES_TO_SUPPRESS,
    documentNumber.toLowerCase()
  );
};

const checkBeneficiaryRole = (eachInvlParty, item) =>
  eachInvlParty?.involvedPartyRole.findIndex(
    eachRole => eachRole === 'Beneficiary'
  ) !== -1 && eachInvlParty?.fullName === item;

const checkBeneficiaryArrayLength = beneficaryArray =>
  beneficaryArray?.length >= 1;

export const canSuppressBeneficiarySection = (details, item) => {
  const status = {
    primary: false,
    secondary: false
  };
  /* eslint-disable */
  if (
    checkBeneficiaryArrayLength(details.beneficiaryDetails.primaryBeneficiaries)
  ) {
    details?.involvedParties.forEach(eachInvlParty => {
      if (checkBeneficiaryRole(eachInvlParty, item)) {
        status.primary = checkDocumentNumber(eachInvlParty);
      }
      return eachInvlParty;
    });
  }
  if (
    checkBeneficiaryArrayLength(
      details.beneficiaryDetails.secondaryBeneficiaries
    )
  ) {
    details?.involvedParties.forEach(eachInvlParty => {
      if (checkBeneficiaryRole(eachInvlParty, item)) {
        status.secondary = checkDocumentNumber(eachInvlParty);
      }
      return eachInvlParty;
    });
  }
  return status;
};
