import * as _ from 'lodash';

/**
 *  Determine if the customer is whitelisted
 * @param functionEligibilityObj: Object
 * @returns: Boolean
 */
const isCustomerWhiteListed = function isCustomerWhiteListed(
  functionID,
  functionEligibilityArryObj = null
) {
  if (functionEligibilityArryObj) {
    const attrsOfFunctionId = _.find(
      functionEligibilityArryObj,
      function eligibility(functionEligibility) {
        return (
          functionEligibility.functionID === functionID ||
          functionEligibility.functionID === 'ALL'
        );
      }
    );
    if (attrsOfFunctionId && attrsOfFunctionId.eligible === false) {
      return false;
    }
  }
  return true;
};
export default isCustomerWhiteListed;
