import styled from 'styled-components';
import { em, rem } from 'polished';
import { FontFamily } from '../utils/font';
import media from '../utils/media';
import theme from '../common/styles/theme';
import { Section } from '../common/styles/commonStyle';

export const Desktop = styled.div`
  display: block;
`;

export const NavBarWrapper = styled.div`
  display: inline;
`;

export const PageWrapper = styled.div`
  background-color: #fff;
`;

export const BackButtonWrapper = styled.div`
  width: 80%;
  display: inline-block;
  padding-top: 13px;
  ${media.tablet`
    padding-top: 22px;
    padding-bottom: 22px;
  `};
  ${media.phone`
    padding-top: 22px;
    padding-bottom: 22px;
  `};
`;

export const InstructionPendingSection = styled(Section)`
  display: block;
  margin-bottom: 0;
  padding-top: ${rem('30px')};
  padding-bottom: ${rem('6px')};
  ${media.tablet`
padding:  ${rem('25px')}  ${rem('20px')} ${rem('0px')} ${rem('20px')};
`};
  ${media.phone`
  padding:  ${rem('20px')}  ${rem('15px')} ${rem('10px')}  ${rem('15px')};
`};
`;
export const PolicyDetailWrapper = styled.div`
  ${media.tablet`
  margin-bottom: ${rem('14px')};
  padding-left: ${rem('0px')};
`};
  ${media.phone`
  margin-bottom: ${rem('11px')};
  padding-left: ${rem('0px')};
  `};
`;

export const Details = styled.div`
  line-height: 1.5;
  margin: ${rem('40px')} ${rem('0px')} ${rem('27px')} ${rem('0px')};
  font-size: 16px;

  ${media.tablet`
    margin: ${rem('40px')} ${rem('0px')} ${rem('10px')} ${rem('0px')};
  `}
  ${media.phone`
    margin: ${rem('40px')} ${rem('0px')} ${rem('10px')} ${rem('0px')};
  `};
`;

export const PlanNameHeader = styled.span`
  font-size: ${em(`32px`)};
  color: ${theme.fontColor};
  line-height: 1.31;

  ${FontFamily.RegularFontW01};
  ${media.tablet`
  font-size: ${em(`28px`)};
  line-height: 1.29;
  `}
  ${media.phone`
  font-size: ${em(`23px`)};
  line-height: 1.3;
  `};
`;

export const LinkTooltipWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: -3px;
   > span {
    padding-right: 10px;
  }
  ${media.phone`
  justify-content: flex-start;
  `}
  > a {
    margin-right: 0;
    > span + span {
      padding-right: 0;
    }
    ${media.phone`
      width: auto;      
  `}
  }
  ${media.tablet`
  > span {
    padding-right: 10px;
  }
  `}
`;
