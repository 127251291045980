import PropTypes from 'prop-types';
import React from 'react';
import { ShowIfDesktop, ShowIfMobile } from '../../../utils/showIf';
import BeneficiaryTableMobile from './BeneficaryTableMobile';
import {
  HeadingBeneficiariesRow,
  ManageBeneficiariesBody,
  FirstHeaderSubFieldContainer,
  SecondaryBeneficiariesHasNA
} from './beneficiarySectionStyles';
import BeneficiaryTabelField from './BeneficiaryTabelField';
import BeneficiarySectionNoData from './BeneficiarySectionNoData';
import TooltipWrapper from '../../../common/tooltipWrapper/TooltipWrapper';

const BeneficiarySectionTableContainer = (props, context) => {
  const {
    intl: { formatMessage }
  } = context;
  const {
    primaryBeneficiariesList,
    rawPrimaryBeneficiariesList,
    type,
    linkRefPrimary,
    linkRefSecondary,
    nothingDataStore,
    irrevocableAssigned,
    canSuppressBeneficiaryLink,
    isPrimaryBeneficiariesHasNA,
    isSecondaryBeneficiariesHasNA
  } = props;

  return nothingDataStore ? (
    <BeneficiarySectionNoData type={type} />
  ) : (
    <>
      {isPrimaryBeneficiariesHasNA ? null : (
        <>
          {type === 'primary' && rawPrimaryBeneficiariesList.length !== 0 && (
            <>
              <HeadingBeneficiariesRow ref={linkRefPrimary}>
                {formatMessage({ id: 'manage_Beneficiaries_List_Primary' })}
              </HeadingBeneficiariesRow>
              <ManageBeneficiariesBody>
                <ShowIfDesktop>
                  <BeneficiaryTabelField
                    fieldData={primaryBeneficiariesList}
                    type="whoIsCovered"
                    rawData={rawPrimaryBeneficiariesList}
                    irrevocableAssigned={irrevocableAssigned}
                    beneficiaryType={type}
                  />
                </ShowIfDesktop>
                <ShowIfMobile>
                  <BeneficiaryTableMobile
                    fieldData={primaryBeneficiariesList}
                    type="whoIsCovered"
                    rawData={rawPrimaryBeneficiariesList}
                    irrevocableAssigned={irrevocableAssigned}
                    beneficiaryType={type}
                  />
                </ShowIfMobile>
              </ManageBeneficiariesBody>
            </>
          )}
          {!canSuppressBeneficiaryLink ? (
            <>
              {type !== 'primary' && rawPrimaryBeneficiariesList.length !== 0 && (
                <>
                  <FirstHeaderSubFieldContainer ref={linkRefSecondary}>
                    <HeadingBeneficiariesRow>
                      {formatMessage({
                        id: 'manage_Beneficiaries_List_Secondary'
                      })}
                    </HeadingBeneficiariesRow>
                    <TooltipWrapper
                      id="secondaryBeneficiaryTooltip"
                      contentKey="secondaryBeneficiaryTooltip"
                      sectionHeading=" "
                      labelContent=" "
                      maxWidth={300}
                    />
                  </FirstHeaderSubFieldContainer>
                  {isSecondaryBeneficiariesHasNA ? (
                    <SecondaryBeneficiariesHasNA>
                      {formatMessage({
                        id: 'manage_Beneficiaries_no_secondary_beneficiaries'
                      })}
                    </SecondaryBeneficiariesHasNA>
                  ) : (
                    <ManageBeneficiariesBody>
                      <ShowIfDesktop>
                        <BeneficiaryTabelField
                          fieldData={primaryBeneficiariesList}
                          type="whoIsCovered"
                          rawData={rawPrimaryBeneficiariesList}
                          beneficiaryType={type}
                        />
                      </ShowIfDesktop>
                      <ShowIfMobile>
                        <BeneficiaryTableMobile
                          fieldData={primaryBeneficiariesList}
                          type="whoIsCovered"
                          rawData={rawPrimaryBeneficiariesList}
                          irrevocableAssigned={irrevocableAssigned}
                          beneficiaryType={type}
                        />
                      </ShowIfMobile>
                    </ManageBeneficiariesBody>
                  )}
                </>
              )}
              {type !== 'primary' && rawPrimaryBeneficiariesList.length === 0 && (
                <>
                  <FirstHeaderSubFieldContainer>
                    <HeadingBeneficiariesRow ref={linkRefSecondary}>
                      {formatMessage({
                        id: 'manage_Beneficiaries_List_Secondary'
                      })}
                    </HeadingBeneficiariesRow>
                    <TooltipWrapper
                      id="secondaryBeneficiaryTooltip"
                      contentKey="secondaryBeneficiaryTooltip"
                      labelContent=" "
                      sectionHeading=" "
                      maxWidth={300}
                    />
                  </FirstHeaderSubFieldContainer>

                  <BeneficiarySectionNoData type={type} />
                </>
              )}
            </>
          ) : null}
        </>
      )}
    </>
  );
};

BeneficiarySectionTableContainer.propTypes = {
  primaryBeneficiariesList: PropTypes.arrayOf(PropTypes.any),
  rawPrimaryBeneficiariesList: PropTypes.arrayOf(PropTypes.any),
  type: PropTypes.string,
  nothingDataStore: PropTypes.bool.isRequired,
  irrevocableAssigned: PropTypes.bool.isRequired,
  linkRefPrimary: PropTypes.objectOf(PropTypes.any).isRequired,
  linkRefSecondary: PropTypes.objectOf(PropTypes.any).isRequired,
  canSuppressBeneficiaryLink: PropTypes.bool.isRequired,
  isPrimaryBeneficiariesHasNA: PropTypes.string.isRequired,
  isSecondaryBeneficiariesHasNA: PropTypes.string.isRequired
};

BeneficiarySectionTableContainer.defaultProps = {
  primaryBeneficiariesList: [],
  rawPrimaryBeneficiariesList: [],
  type: 'primary'
};

BeneficiarySectionTableContainer.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default BeneficiarySectionTableContainer;
