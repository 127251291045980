import * as _ from 'lodash';

export const summaryWarningMessageResolver = function summaryWarningMessageResolver(
  policyByEncodeId
) {
  const warningMessage = [];
  let aplAlert = {
    label: '',
    value: false
  };
  let overdueAlert = {
    label: '',
    value: false
  };

  // to check APL/Overdue indicators and generate warningMessage array
  _.some(policyByEncodeId, encodeID => {
    if (
      encodeID.policyAmountDetails &&
      encodeID.policyAmountDetails.overdueAlertIndicator
    ) {
      overdueAlert = {
        label: 'overdueAlertIndicatorLabel',
        value: true
      };
      // check if label for APL/Overdue indicator present in alerts array, if not present then push APL/Overdue into warningMessage array
      const isExistLabel = warningMessage.some(
        alert => alert.label === overdueAlert.label
      );
      if (!isExistLabel) {
        warningMessage.push(overdueAlert);
      }
    }
    if (
      encodeID.policyAmountDetails &&
      encodeID.policyAmountDetails.aplAlertIndicator
    ) {
      aplAlert = {
        label: 'aplAlertIndicatorLabel',
        value: true
      };
      // check if label for APL/Overdue indicator present in alerts array, if not present then push APL/Overdue into warningMessage array
      const isExistLabel = warningMessage.some(
        alert => alert.label === aplAlert.label
      );
      if (!isExistLabel) {
        warningMessage.push(aplAlert);
      }
    }
  });
  return warningMessage;
};

/**
 *
 * @param {*} warningOrErrorBundle : object => {errors: [{}], warnings: [{}] }
 * @description: Handles logic for mapping of errors/warnings to text to be displayed in the frontent.Goes as an input to the WarningsOrErrors Component.
 */

export const apiErrorOrWarningResolver = function apiErrorOrWarningResolver(
  warningOrErrorBundle
) {
  const { errors, warnings } = warningOrErrorBundle;
  if (errors) {
    return { textId: 'full_failure_message', displayAs: 'WarningOrErrors' };
  }
  if (warnings) {
    const noPoliciesPresent = warnings.find(
      warning => warning.warningCode === 'EXP0004'
    );
    if (noPoliciesPresent) {
      return { textId: 'has_no_policy_message', displayAs: 'PlainText' };
    }
    return { textId: 'partial_failure_message', displayAs: 'WarningOrErrors' };
  }
  return { textId: '', displayAs: '' };
};

export const getExploreProductLink = function getExploreProductLink(
  productLink,
  locale
) {
  if (typeof productLink === 'object') {
    return productLink[locale];
  }
  return productLink;
};
